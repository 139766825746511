import React, { useEffect, useState } from "react";
import { Card, Col, Modal, Button } from "react-bootstrap";
import toast from "react-hot-toast";
import { addMedicalRecord } from "../../services/DashboardAsyncFunctions";

function VideoCallFormFillAppointment({
  onHide,
  show,
  appointmentId,
  medicalRecord,
  getAppointmentDetails,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [callData, setCallData] = useState({
    other:medicalRecord?.other ? medicalRecord?.other : "",
    vitals: medicalRecord?.vitals ? medicalRecord?.vitals : "",
    chiefOfComplaint: medicalRecord?.chiefOfComplaint ? medicalRecord?.chiefOfComplaint : "",
    hpi: medicalRecord?.hpi ? medicalRecord?.hpi : "",
    physicalExam: medicalRecord?.physicalExam ? medicalRecord?.physicalExam : "",
    diagnosis: medicalRecord?.diagnosis ? medicalRecord?.diagnosis : "",
    assessment: medicalRecord?.assessment ?medicalRecord?.assessment : "",
    investigation: medicalRecord?.investigation ? medicalRecord?.investigation : "" ,
    medications: medicalRecord?.medications ? medicalRecord?.medications : "",
    plan: medicalRecord?.plan ? medicalRecord?.plan : "",
  });

  async function submit() {
    try {
      setIsLoading(true);
      const response = await addMedicalRecord({ ...callData, appointmentId });
      if (response.status) {
        onHide();
        getAppointmentDetails();
        toast.success("Form submitted successfully");
      }
    } catch (error) {
      console.log(error, "error");
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Modal
      className="modal fade"
      backdrop="static"
      centered
      onHide={onHide}
      show={show}
    >
      <Col>
        <Card>
          <Card.Header className="d-block">
            <div className="d-flex justify-content-between align-items-center">
              <h3>Add Medical Record</h3>
              <Button
                variant=""
                className="close"
                onClick={() => {
                  onHide();
                }}
              >
                <span style={{ fontSize: "30px" }}>&times;</span>
              </Button>
            </div>
          </Card.Header>

          <Card.Body>
            <form className="d-flex align-items-start flex-column gap-2">
              <div style={{ width: "100%" }}>
                <h6>Notes</h6>
                <textarea
                  value={callData.other}
                  onChange={(e) =>
                    setCallData((prev) => ({ ...prev, other: e.target.value }))
                  }
                  className="form-control mr-2"
                  style={{ minHeight: "100px" }}
                  name="other"
                />
              </div>

              <div style={{ width: "100%" }}>
                <h6>Vitals</h6>
                <input
                  type="text"
                  value={callData.vitals}
                  onChange={(e) =>
                    setCallData((prev) => ({ ...prev, vitals: e.target.value }))
                  }
                  className="form-control mr-2"
                  name="vitals"
                />
              </div>
              <div style={{ width: "100%" }}>
                <h6>Chief of complaint</h6>
                <input
                  type="text"
                  value={callData.chiefOfComplaint}
                  onChange={(e) =>
                    setCallData((prev) => ({
                      ...prev,
                      chiefOfComplaint: e.target.value,
                    }))
                  }
                  className="form-control mr-2"
                  name="chiefOfComplaint"
                />
              </div>

              <div style={{ width: "100%" }}>
                <h6>HPI</h6>
                <input
                  type="text"
                  value={callData.hpi}
                  onChange={(e) =>
                    setCallData((prev) => ({ ...prev, hpi: e.target.value }))
                  }
                  className="form-control mr-2"
                  name="hpi"
                />
              </div>

              <div style={{ width: "100%" }}>
                <h6>Physical Exam</h6>
                <input
                  type="text"
                  value={callData.physicalExam}
                  onChange={(e) =>
                    setCallData((prev) => ({
                      ...prev,
                      physicalExam: e.target.value,
                    }))
                  }
                  className="form-control mr-2"
                  name="physicalExam"
                />
              </div>

              <div style={{ width: "100%" }}>
                <h6>Diagnosis</h6>
                <input
                  type="text"
                  value={callData.diagnosis}
                  onChange={(e) =>
                    setCallData((prev) => ({
                      ...prev,
                      diagnosis: e.target.value,
                    }))
                  }
                  className="form-control mr-2"
                  name="diagnosis"
                />
              </div>

              <div style={{ width: "100%" }}>
                <h6>Assessment</h6>
                <input
                  type="text"
                  value={callData.assessment}
                  onChange={(e) =>
                    setCallData((prev) => ({
                      ...prev,
                      assessment: e.target.value,
                    }))
                  }
                  className="form-control mr-2"
                  name="assessment"
                />
              </div>

              <div style={{ width: "100%" }}>
                <h6>Investigation</h6>
                <input
                  type="text"
                  value={callData.investigation}
                  onChange={(e) =>
                    setCallData((prev) => ({
                      ...prev,
                      investigation: e.target.value,
                    }))
                  }
                  className="form-control mr-2"
                  name="investigation"
                />
              </div>

              <div style={{ width: "100%" }}>
                <h6>Medications</h6>
                <input
                  type="text"
                  value={callData.medications}
                  onChange={(e) =>
                    setCallData((prev) => ({
                      ...prev,
                      medications: e.target.value,
                    }))
                  }
                  className="form-control mr-2"
                  name="medications"
                />
              </div>

              <div style={{ width: "100%" }}>
                <h6>Plan</h6>
                <input
                  type="text"
                  value={callData.plan}
                  onChange={(e) =>
                    setCallData((prev) => ({ ...prev, plan: e.target.value }))
                  }
                  className="form-control mr-2"
                  name="Plan"
                />
              </div>
            </form>
          </Card.Body>
          <Card.Footer>
            <button
              className="btn btn-primary w-100"
              onClick={submit}
              disabled={isLoading}
            >
              {isLoading ? "Loading" : "Add Data"}
            </button>
          </Card.Footer>
        </Card>
      </Col>
    </Modal>
  );
}

export default VideoCallFormFillAppointment;
