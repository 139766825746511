import axios from "axios";
import { loginConfirmedAction, logout } from "../store/actions/AuthActions";
import { CHANGE_PASSWORD, DASHBOARD, EDIT_USER, LOGIN, LOGOUT, REGISTER_USER, SLOT_BOOK } from "./AuthApiEndPoints";
import axiosInstance from "./AxiosInstance";
import { notifyError } from "../jsx/common/Toaster";

export function signUp(email, password) {
  //axios call

  const postData = {
    email,
    password,
    returnSecureToken: true,
  };

  return axios.post(
    `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    postData
  );
}

export function login(email, password, type) {
  const postData = {
    email,
    password,
    type
  };

  return axiosInstance.post(LOGIN, postData);
}

export function dashboard(filter) {
  return axiosInstance.get(DASHBOARD + `?data=${filter}`);
}
export function changePasswoard(oldPassword, newPassword) {
  const postData = {
    oldPassword,
    newPassword,
  };
  return axiosInstance.put(CHANGE_PASSWORD, postData);
}

export async function registerUser(payload){
  return axiosInstance.post(REGISTER_USER, payload);
}

export async function editUser(payload){
  return axiosInstance.put(EDIT_USER, payload);
}

export function logOut() {
  return axiosInstance.post(LOGOUT, { test: "" });
}

export function formatError(errorResponse) {
  notifyError(errorResponse);
}

export function saveTokenInLocalStorage(tokenDetails) {
  localStorage.setItem("_anytime_doctor_token_", tokenDetails);
}

export function saveTypeInLocalStorage(doctorType) {
  localStorage.setItem("_anytime_doctor_type_", doctorType);
}

export function saveTypeValueInLocalStorage(doctorData) {
  localStorage.setItem("_anytime_doctor_type_value", JSON.stringify(doctorData));
}

export function saveUserCacheDataInLocalStorage(userCacheData) {
  localStorage.setItem("_anytime_user_", userCacheData);
}

export function removeUserCacheDataInLocalStorage() {
  localStorage.removeItem("_anytime_user_");
}

export function getUserCacheDataFromLocalStorage(){
return JSON.parse(localStorage.getItem("_anytime_user_"));
}

export function getUserToken(){
  return localStorage.getItem("_anytime_doctor_token_");
}



export function runLogoutTimer(dispatch, timer, history) {
  setTimeout(() => {
    dispatch(logout(history));
  }, timer);
}

export function checkAutoLogin(dispatch, history, pathname) {
  const tokenDetailsString = localStorage.getItem("_anytime_doctor_token_");
  if (!tokenDetailsString) {
    dispatch(logout(history, pathname));
    return;
  }
  dispatch(loginConfirmedAction(tokenDetailsString));
}
