import { SELECT_CONVERSATION, SELECT_CONVERSATION_NULL, VIDEO_CALL_TOGGLE } from "./PostTypes";

export function selectConversationForChat(data) {
    return {
        type: SELECT_CONVERSATION,
        payload: data,
    };
}

export function selectConversationNull() {
    return {
        type: SELECT_CONVERSATION_NULL,
        payload: null,
    };
}

export function videoCallAction(data) {
    return {
        type: VIDEO_CALL_TOGGLE,
        payload: data,
    };
}