import { SELECT_CONVERSATION, VIDEO_CALL_TOGGLE } from '../actions/PostTypes';

const initialState = {
    selectedConversationDetails: null,
    videoCall: false,
};

export default function SessionReducer(state = initialState, actions) {
    if(actions.type === SELECT_CONVERSATION){
        let payload = {
            conversationId: actions.payload.conversationId,
            otherPersonName: actions.payload.name,
            otherPersonId: actions.payload.Id,
            otherPersonImage: actions.payload.image,
            otherPersonEmail: actions.payload.email
        }
        return{
            ...state,
            selectedConversationDetails: {
                ...payload
            }
        }
    }
    if(actions.type === VIDEO_CALL_TOGGLE){
        return{
            ...state,
            videoCall: actions.payload
        }
    }else{
        return{
            ...state,
            selectedConversationDetails: null
        }
    }
}
