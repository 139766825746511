import React, { useEffect, useState } from "react";
import { Spinner, Tab, Tabs } from "react-bootstrap";
import PatientCard from "../components/cards/PatientCard";
import { getPatients } from "../../services/DashboardAsyncFunctions";
import toast from "react-hot-toast";

function Patients() {
  const [patients, setPatients] = useState(null);
  const [key, setKey] = useState("patient");
  const [hasMoreData, setHasMoreData] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [searchshow, setSearchShow] = useState(false);

  async function getMyPatients() {
    setIsLoading(true);
    setSearchShow(false);
    if (searchshow) {
      setCurrentPage(0);
      try {
        let response = await getPatients(0, limit, search);
        console.log(response.data);

        let updatedList = response.data.data;
        setPatients(updatedList);
        setHasMoreData(response.data.data?.length < limit ? false : true);
      } catch (error) {
        toast.error(error?.response?.data?.message);
      } finally {
        setIsLoading(false);
      }
    } else {
      try {
        let response = await getPatients(currentPage, limit, search);
        console.log(response.data);

        let updatedList = patients
          ? [...patients, ...response.data.data]
          : [...response.data.data];
        setPatients([...updatedList]);
        setHasMoreData(response.data.data?.length < limit ? false : true);
      } catch (error) {
        toast.error(error?.response?.data?.message);
      } finally {
        setIsLoading(false);
      }
    }
  }

  async function getMyPatientsBySearch() {
    setIsLoading(true);
    setSearchShow(true);
    try {
      let response = await getPatients(currentPage, limit, search);
      console.log(response.data);

      let updatedList = response.data.data;
      setPatients(updatedList);
      setHasMoreData(response.data.data?.length < limit ? false : true);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  }

  function loadMore() {
    setCurrentPage((prev) => prev + 1);
  }

  useEffect(() => {
    if (search === "") {
      getMyPatients();
    }
  }, [currentPage,search]);

  useEffect(() => {
    if (search !== "") {
      getMyPatientsBySearch();
    }
  }, [search]);

  return (
    <div>
      <div className="mb-3">
        <div className="d-flex align-items-center justify-content-between">
          <h2 className="text-black font-w600 mb-0">Patients</h2>
          <div>
            <input
              className="input-search form-control"
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
      </div>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="patient" title="My Patients">
          {patients &&
            patients.map((item) => (
              <PatientCard
                key={item._id}
                patientName={item?.name}
                gender={item?.gender}
                patientEmail={item?.email}
                patientImage={item?.image}
                patientId={item._id}
                appointmentStatus={item.detailStatus}
                lastAppointmentDate={item.updatedAt}
              />
            ))}
          {patients && patients.length == 0 && (
            <div className="text-center">
              <h3>No Data</h3>
            </div>
          )}
          {isLoading && (
            <div className="d-flex align-items-center justify-content-center mb-3">
              <Spinner animation="border" variant="info" />
            </div>
          )}
          <div className="d-flex align-items-center justify-content-center">
            {hasMoreData && !isLoading && (
              <button
                className="fs-14 p-2 px-3 btn-warning btn m-auto"
                onClick={() => loadMore()}
              >
                Load more
              </button>
            )}
          </div>
        </Tab>
      </Tabs>
    </div>
  );
}

export default Patients;
