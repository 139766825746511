import React, { useEffect, useRef } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import { IoChatboxEllipsesOutline } from "react-icons/io5";
import { IoCalendarOutline } from "react-icons/io5";
import { MdOutlineDashboard } from "react-icons/md";
import { PiHospitalLight } from "react-icons/pi";
import { useDispatch } from "react-redux";
import { selectConversationNull } from "../../../store/actions/SessionActions";

const iconColor = "#0cc0de";

const SideBar = () => {
  const loginType = localStorage.getItem("_anytime_doctor_type_");
  const dataType = JSON.parse(
    localStorage.getItem("_anytime_doctor_type_value")
  );
  const dispatch = useDispatch();
  const elRef = useRef(null);

  useEffect(() => {
    const btn = document.querySelector(".nav-control");
    const aaa = document.querySelector("#main-wrapper");

    const toggleFunc = () => aaa.classList.toggle("menu-toggle");
    btn.addEventListener("click", toggleFunc);

    const dashboardElements = [
      ".dashboard",
      ".appointments",
      ".chat",
      ".patients",
      ".add-assistant",
      ".availability",
      ".fees",
    ];

    if (window.innerWidth < 1300) {
      dashboardElements.forEach((selector) => {
        const btnElement = document.querySelector(selector);
        if (btnElement) {
          btnElement.addEventListener("click", toggleFunc);
        }
      });
    }

    return () => {
      btn?.removeEventListener("click", toggleFunc);
      dashboardElements.forEach((selector) => {
        const btnElement = document.querySelector(selector);
        if (btnElement) {
          btnElement.removeEventListener("click", toggleFunc);
        }
      });
    };
  }, []);

  const path = window.location.pathname.split("/").pop();

  const handlerChat = () => {
    dispatch(selectConversationNull());
  };

  return (
    <div className="deznav">
      <PerfectScrollbar className="deznav-scroll">
        <div className="mm-wrapper">
          <ul className="metismenu" ref={elRef}>
            {loginType === "Doctor" && (
              <li className={`dashboard ${path === "" ? "mm-active" : ""}`}>
                <Link className="ai-icon" to="/">
                  <MdOutlineDashboard size={"30px"} color={iconColor} />
                  <span className="nav-text pl-4">My Dashboard</span>
                </Link>
              </li>
            )}

            {(loginType === "Doctor" ||
              (loginType === "assistantDoctor" && dataType.appointments)) && (
              <li
                className={`appointments ${
                  path === "appointments" ? "mm-active" : ""
                }`}
              >
                <Link to="/appointments" className="ai-icon">
                  <IoCalendarOutline size={"30px"} color={iconColor} />
                  <span className="nav-text pl-4">Appointments</span>
                </Link>
              </li>
            )}

            {loginType === "Doctor" && (
              <li
                className={`chat ${path === "chat" ? "mm-active" : ""}`}
                onClick={handlerChat}
              >
                <Link to="/chat" className="ai-icon">
                  <IoChatboxEllipsesOutline size={"30px"} color={iconColor} />
                  <span className="nav-text pl-4">Chat</span>
                </Link>
              </li>
            )}

            {loginType === "Doctor" && (
              <li
                className={`patients ${path === "patients" ? "mm-active" : ""}`}
              >
                <Link to="/patients" className="ai-icon">
                  <PiHospitalLight size={"30px"} color={iconColor} />
                  <span className="nav-text pl-4">Patients</span>
                </Link>
              </li>
            )}

            {loginType === "Doctor" && (
              <li
                className={`add-assistant ${
                  path === "add-assistant" ? "mm-active" : ""
                }`}
              >
                <Link to="/add-assistant" className="ai-icon">
                  <PiHospitalLight size={"30px"} color={iconColor} />
                  <span className="nav-text pl-4">Add Assistant</span>
                </Link>
              </li>
            )}

            {loginType === "Doctor" && (
              <li
                className={`availability ${
                  path === "availability" ? "mm-active" : ""
                }`}
              >
                <Link to="/availability" className="ai-icon">
                  <PiHospitalLight size={"30px"} color={iconColor} />
                  <span className="nav-text pl-4">Availablity</span>
                </Link>
              </li>
            )}

             {loginType === "Doctor" && (
              <li
                className={`fees ${
                  path === "fees" ? "mm-active" : ""
                }`}
              >
                <Link to="/fees" className="ai-icon">
                  <PiHospitalLight size={"30px"} color={iconColor} />
                  <span className="nav-text pl-4">Fees Structure</span>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
