import React, { useEffect, useLayoutEffect, useState } from "react";
import ConversationList from "../components/chat/ConversationList";
import MessagesList from "../components/chat/MessagesList";
import socketServices from "../../services/SocketServices";
import { useSelector } from "react-redux";
import {
  getChatMessageList,
  getProfileFetch,
} from "../../services/DashboardAsyncFunctions";
import "../../css/chat.css";

function Chat() {
  const [messageList, setMessageList] = useState([]);
  const selectedConversation = useSelector(
    (state) => state.session.selectedConversationDetails
  );
  const [isChatFetching, setIsChatFetching] = useState(false);
  const [screenWidth] = useState(window.innerWidth);
  const [profileData, setProfileData] = useState({});

  async function getProfileData() {
    try {
      const response = await getProfileFetch();
      setProfileData(response.data?.data);
    } catch (error) {
      console.log(error, "error");
    }
  }

  useEffect(() => {
    getProfileData();
  }, []);

  async function initialConnect() {
    setMessageList([]);
    if (selectedConversation) {
      setTimeout(() => {
        socketServices.emit("joinConversation", {
          conversationId: selectedConversation?.conversationId,
          userId: profileData._id,
        });
      }, 500);
    }
    socketServices.on("receiveMessage", (data) => {
      setMessageList((prev) => [...prev, data]);
    });

    socketServices.on("joinedConversation", (data) => {
      getChatMessages(data);
    });

    socketServices.on("leavedConversation", (data) => {
      console.log(data);
    });
  }

  function leaveConversation() {
    if (selectedConversation) {
      socketServices.emit("leaveConversation", {
        conversationId: selectedConversation?.conversationId,
        userId: "65ddaa3ee21f22008fb49db5",
      });
    }
    socketServices.removeListener("receiveMessage");
    socketServices.removeListener("joinedConversation");
  }

  async function getChatMessages(conversationId) {
    setIsChatFetching(true);
    try {
      const response = await getChatMessageList(conversationId);
      console.log(response);
      setMessageList(response.data?.data?.chat.reverse());
    } catch (error) {
      console.log(error, "error");
    } finally {
      setIsChatFetching(false);
    }
  }

  useEffect(() => {
    initialConnect();
    return () => leaveConversation();
  }, [selectedConversation]);

  useLayoutEffect(() => {
    document.body.classList.add("no-scroll");
    return () => document.body.classList.remove("no-scroll");
  }, []);

  return (
    <div className="chat-page">
      {screenWidth > 500 ? (
        <ConversationList />
      ) : (
        <div className="mobile-placeholder">
          {!selectedConversation && <ConversationList />}
        </div>
      )}
      {screenWidth > 500 ? (
        <MessagesList
          messageList={messageList}
          isChatFetching={isChatFetching}
          conversationSelectedTrue={selectedConversation}
        />
      ) : (
        <div className="mobile-placeholder">
          {selectedConversation && (
            <MessagesList
              messageList={messageList}
              isChatFetching={isChatFetching}
              conversationSelectedTrue={selectedConversation}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default Chat;
