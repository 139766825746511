import toast from 'react-hot-toast';
import {
    formatError,
    login,
    removeUserCacheDataInLocalStorage,
    runLogoutTimer,
    saveTokenInLocalStorage,
    saveTypeInLocalStorage,
    saveTypeValueInLocalStorage,
    saveUserCacheDataInLocalStorage,
    signUp,
} from '../../services/AuthService';

export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';

export function signupAction(email, password, history) {
    return (dispatch) => {
        signUp(email, password)
        .then((response) => {
            saveTokenInLocalStorage(response.data);
            runLogoutTimer(
                dispatch,
                response.data.expiresIn * 1000,
                history,
            );
            dispatch(confirmedSignupAction(response.data));
            history.push('/');
        })
        .catch((error) => {
            const errorMessage = formatError(error.response.data);
            dispatch(signupFailedAction(errorMessage));
        });
    };
}

export function logout(history, activePathname) {
    localStorage.removeItem('_anytime_doctor_token_');
    removeUserCacheDataInLocalStorage();
    if(activePathname == "/user-registration"){
        history.push("/user-registration");
    }else{
        history.push("/");
    }

    return {
        type: LOGOUT_ACTION,
    };
}

export function loginAction(email, password, type, history, setError) {
    return (dispatch) => {
        login(email, password, type)
            .then((response) => {
                console.log(response);
                toast.success("Login successful");
                setError(null);
                saveTokenInLocalStorage(response.data.value.token);
                saveTypeInLocalStorage(response.data.data.type)
                saveTypeValueInLocalStorage(response.data.data.assistantDoctorPermissions ? response.data.data.assistantDoctorPermissions : {})
                saveUserCacheDataInLocalStorage(JSON.stringify({email: response.data.data.email, name:response.data.data.name, isLoggedIn: true, documentStatus: response.data.data.detailStatus}))
                dispatch(loginConfirmedAction(response.data.value.token));
                dispatch(loadingToggleAction(false));
                if(response.data.value.type == "Doctor"){
                    history.push('/dashboard');
                }else if(response.data.data.type == "assistantDoctor"){
                    history.push('/appointments');
                }
            })
            .catch((error) => {
                console.log(error.response);
                if(error.response.data.message === "Sorry This User Not Verify"){
                    toast.error("Verify your email and try again");
                    setError("Verify your email and try again");
                }else{
                    toast.error(error.response.data.message);
                }
                
                dispatch(loadingToggleAction(false));
                const errorMessage = formatError(error?.response?.data?.message);
                dispatch(loginFailedAction(errorMessage));
            });
    };
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
